import React, { Component } from "react";
import ContactImage from "./contact-image";
import IconTextLabel from "./icon-text-label";
import { faBorderNone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

class ContactCardLarge extends Component {
  render() {
    const { firstName, lastName, role, imageURI, email, linkedIn } = this.props;

    const buttonSize = 30;

    return (
      <div className="container-fluid gz-layer rounded">
        <div className="row">
          <div className="col-3 p-3">
            <ContactImage
              imageSrc={imageURI}
              imageName={firstName}
              imageSize={100}
            />
          </div>
          <div className="col-9">
            <div className="p-3">
              <div className="text-light fw-bold">
                <div className="fs-2">
                  <span className="text-weight-bold">{firstName}</span>{" "}
                  {lastName}
                </div>
                <p className="fs-6 fw-light">{role}</p>

                <div
                  style={{ gap: "5px" }}
                  className="d-flex flex-row align-items-center"
                >
                  <a
                    href={`mailto:${email}?subject=Hi ${firstName}`}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      background: "rgb(247,147,30)",
                      width: `${buttonSize}px`,
                      height: `${buttonSize}px`,
                      borderRadius: `${buttonSize / 2}px`,
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-envelope-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                    </svg>
                  </a>

                  <a
                    href={linkedIn}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      background: "rgb(247,147,30)",
                      width: `${buttonSize}px`,
                      height: `${buttonSize}px`,
                      borderRadius: `${buttonSize / 2}px`,
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-linkedin m-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactCardLarge;

import React, { Component } from "react";
import HeadGearLogo from "./headgearlogo";

class Header extends Component {
  render() {
    return (
      <div className="p-4 container-fluid">
        <div className="row">
          <div className="col-sm-6">
            <div className="d-flex justify-content-center justify-content-sm-start">
              <HeadGearLogo />
            </div>
          </div>
          <div className="col-sm-6"></div>
        </div>
      </div>
    );
  }
}

export default Header;

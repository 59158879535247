import React, { Component } from "react";
import Sketch from "react-p5";
import { Particle } from "../hg/headgear_brain";

export default class HeadgearHead extends Component {
  p = [];
  particlesLength;

  setup = (p5, parent) => {
    p5.createCanvas(window.innerWidth, window.innerHeight).parent(parent);
    this.particlesLength = Math.floor(window.innerWidth / 10);
    for (let index = 0; index < this.particlesLength; index++) {
      this.p.push(new Particle(p5));
    }
  };

  draw = (p5) => {
    p5.clear();
    // p5.background("#69614e");
    this.p.forEach((particle, index) => {
      particle.update();
      particle.draw();
      particle.checkParticles(this.p.slice(index));
    });
  };

  render() {
    return <Sketch setup={this.setup} draw={this.draw} />;
  }
}

import HGSectionContent from "./hg-section-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { faStethoscope } from "@fortawesome/free-solid-svg-icons";

const HGSection = () => {
  return (
    <div
      style={{ marginTop: "120px", backgroundColor: "rgba(255,255,255,0.1)" }}
      className="container-fluid p-5"
    >
      <div className="row justify-content-center">
        <div className="col-md-3 col-12">
          <div className="d-flex justify-content-center">
            <HGSectionContent
              title="Intuitive Asset Management"
              body="Ever felt like you haven't had the right info about your products, in the right place, at the right time to make an educated choice?"
            >
              <FontAwesomeIcon
                style={{ fontSize: "7em", color: "white" }}
                icon={faBoxes}
              />
            </HGSectionContent>
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="d-flex justify-content-center">
            <HGSectionContent
              title="Applied Artifical Intelligence"
              body="So you have the problem and the data but can't quite find the solution? Intuitive, doesn't just learn, it teaches itself how to learn"
            >
              <FontAwesomeIcon
                style={{ fontSize: "7em", color: "white" }}
                icon={faGraduationCap}
              />
            </HGSectionContent>
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="d-flex justify-content-center">
            <HGSectionContent
              title="Intelligent Diagnostics and Prognostics"
              body="Do you know a human or machine that can accurately self-diagnose and predict degredation in health?"
            >
              <FontAwesomeIcon
                style={{ fontSize: "7em", color: "white" }}
                icon={faStethoscope}
              />
            </HGSectionContent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HGSection;

const HGSectionContent = (props) => {
  console.log(props);
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center align-items-center">
        {props.children}
      </div>
      <h5
        style={{ fontSize: "1.6em", fontWeight: "600" }}
        className="d-flex justify-content-center align-items-center text-light my-3 text-center"
      >
        {props.title}
      </h5>
      <p className="text-light ">{props.body}</p>
    </div>
  );
};

export default HGSectionContent;

import './App.css';
import HeadgearHead from './components/head_gear_brain';
import Header from './components/header';
import Banner from './components/banner';
import HGSection from './components/hg_section'
import ContactCardLarge from './components/contact_card_large';
import gaz from './assets/20210407_gazlong_headgear_contact.png';
import brian from './assets/20210407_brianmccartney_headgear_contact.png';
import cyberCert from './assets/cyber-essentials-certification-202010150827163.png';

function App() {

  const buttonSize = 30;

  return (
    <div>
      <HeadgearHead></HeadgearHead>
      <div className="content">

          <Header></Header>
              <Banner />
          <HGSection />

          <div className="container-sm my-2  my-md-5">
              <div className="row">
                <div className="col-md-6 col-12 my-2 my-md-0">
                    <ContactCardLarge firstName="Brian" lastName="McCartney" role="CEO" imageURI={brian} email="brian.mccartney@headgear.ltd" linkedIn="https://www.linkedin.com/in/brian-mccartney-msc-ceng-24796867/" />
                </div>
                <div className="col-md-6 col-12 my-2 my-md-0">
                    <ContactCardLarge firstName="Gareth" lastName="Long" role="CTO" imageURI={gaz} email="gareth.long@headgear.ltd" linkedIn="https://www.linkedin.com/in/gareth-long-61a290a7/" />
                </div>
              </div>
          </div>

          <div  className="container-fluid gz-layer p-3">
            <div class="row">
              <div className="col-sm-4">
                <div className="d-flex align-items-center h-100">
                <div className="text-light">&#169; Copy right 2021 All rights reserved</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="d-flex justify-content-center">
                <img src={cyberCert} alt="Cyber Cert" />
                </div>
              </div>
              <div className="col-sm-4">
                <div style={{gap:'10px'}} className="d-flex flex-row justify-content-end align-items-center h-100">


                <a
                    href="https://www.facebook.com/Headgear-473468249790115"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      background: "white",
                      width: `${buttonSize}px`,
                      height: `${buttonSize}px`,
                      borderRadius: `${buttonSize / 2}px`,
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
</svg>
                  </a>


                <a
                    href="https://www.linkedin.com/company/headgear-ltd/"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                      background: "white",
                      width: `${buttonSize}px`,
                      height: `${buttonSize}px`,
                      borderRadius: `${buttonSize / 2}px`,
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-linkedin m-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          




      </div>
    </div>
  );
}

export default App;

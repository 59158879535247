


export class Particle {
    constructor(p5) {

       this.p5 = p5;
       
       //Position
       this.position = this.p5.createVector(p5.random(p5.width),p5.random(this.p5.height));
    
       //Velocity
       this.vel = this.p5.createVector(this.p5.random(-1,1),this.p5.random(-1,1))

       //Size
       this.size = 2;
    }

    //Update movement by adding vel
    update() {
        this.position.add(this.vel);
        this.edges();
    }

    //Draw a single particle
    draw() {
        this.p5.noStroke();
        this.p5.fill('rgba(255,255,255,0.5)');
        this.p5.circle(this.position.x,this.position.y,this.size);
    }

    edges() {
        if(this.position.x < 0 || this.position.x > this.p5.width) {
            this.vel.x *= -1;
        }

        if(this.position.y < 0 || this.position.x > this.p5.height) {
            this.vel.y *= -1;
        }
    }

    checkParticles(particles) {
        particles.forEach(particle => {
            const d = this.p5.dist(this.position.x,this.position.y,particle.position.x,particle.position.y);
            if(d < 120) {
                this.p5.stroke('rgba(255,255,255,0.1)');
                this.p5.line(this.position.x,this.position.y,particle.position.x,particle.position.y)
            }

        }); 
    }
    
}
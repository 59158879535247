const ContactImage = (props) => {
  const style = {
    width: `${props.imageSize}px`,
    height: `${props.imageSize}px`,
    objectFit: "contain",
    borderRadius: `${props.imageSize / 2}px`,
  };

  return <img style={style} src={props.imageSrc} alt={props.imageName} />;
};

export default ContactImage;

import React, { Component } from "react";
import HeadgearHead from "./headgear_head/headgear_head";

//asdfa

class Banner extends React.Component {
  render() {
    return (
      <div className="container-sm mt-0 mt-sm-5">
        <div className="row justify-content-center">
          <div className="col">
            <div className="d-none d-sm-flex justify-content-center">
              <HeadgearHead />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
